<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="mx-auto"><button type="button" data-bs-dismiss="modal">X</button> ปิดหน้านี้</div>
        </div>
        <div class="modal-body">
          <p class="text-danger fw-bold text-center">ผลเงินรางวัลสูงสุดที่เป็นไปได้</p>
          <div class="row">
            <div class="col-5 text-start">รางวัลที่ 1 ( 1 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>6000000</b> บาท</p>
            </div>
            <div class="col-5 text-start">เลขหน้า 3 ตัว ( 1 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>4000</b> บาท</p>
            </div>
            <div class="col-5 text-start">เลขหลัง 3 ตัว ( 1 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>4000</b> บาท</p>
            </div>
            <div class="col-5 text-start">เลขท้าย 1 ตัว ( 1 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>2000</b> บาท</p>
            </div>
            <div class="col-5 text-start">รางวัลข้างเคียงรางวัลที่ 1 ( 1 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>100000</b> บาท</p>
            </div>
            <div class="col-5 text-start">รางวัลที่ 2 ( 5 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>200000</b> บาท</p>
            </div>
            <div class="col-5 text-start">รางวัลที่ 3 ( 10 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>80000</b> บาท</p>
            </div>
            <div class="col-5 text-start">รางวัลที่ 4 ( 50 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>40000</b> บาท</p>
            </div>
            <div class="col-5 text-start">รางวัลที่ 5 ( 100 รางวัล )</div>
            <div class="col-3">1 ใบ</div>
            <div class="col-4">
              <p class="text-end"><b>20000</b> บาท</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModelFade'
}
</script>
