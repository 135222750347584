<template>
  <div class="flex-grow">
    <div class="container mt-3" v-if="hasOrderDetail">
      <div class="flex flex-row">
        <aside class="mx-5 p-2 w-1/3">
          <div class="mt-3">
            <h5 class="text-start text-secondary text-lg font-bold">รายการสั่งซื้อ <span class="badge bg-success">รอตรวจสอบคำสั่งซื้อ</span></h5>
          </div>
          <div class="mt-3">
            <div class="card shadow-md">
              <div class="card-body">
                <p class="text-start">ID : {{ orderDetail.id }}</p>
                <p class="text-start">วัน-เวลาที่สั่งซื้อ {{ orderDetail.created_at }}</p>
                <p class="text-start text-success">งวดวันที่ {{ orderDetail.lotto_date }}</p>
              </div>
            </div>
          </div>
          <!-- end detail order part 1 -->
          <div class="mt-3">
            <div class="card shadow-md">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <p class="text-start text-success">ข้อมูลผู้ซื้อ</p>
                  </div>
                  <div class="col-6">
                    <span class="badge bg-secondary">เงื่อนไขการสั่งซื้อ</span>
                  </div>
                </div>
                <p class="text-start text-success">ชื่อ : {{ orderDetail.user.user_profile.name + orderDetail.user.user_profile.lastname }}</p>
                <p class="text-start text-success">เบอร์โทร : {{ orderDetail.user.user_profile.phone }}</p>
                <p class="text-start text-success">เฟสบุค : {{ orderDetail.user.user_profile.facebook }}</p>
                <p class="text-start text-success">ไลน์ : {{ orderDetail.user.user_profile.line }}</p>
                <hr class="text-success" />
                <p class="text-start text-success">ข้อมูลผู้ขาย</p>
                <p class="text-start text-success">ชื่อ : {{ orderDetail.reseller.user.user_profile.name + orderDetail.reseller.user.user_profile.lastname }}</p>
                <p class="text-start text-success">เบอร์โทร : {{ orderDetail.reseller.user.user_profile.line }}</p>
                <p class="text-start text-success">เฟสบุค : {{ orderDetail.reseller.user.user_profile.line }}</p>
                <p class="text-start text-success">ไลน์ : {{ orderDetail.reseller.user.user_profile.line }}</p>
                <hr class="text-success" />
                <div class="flex flex-col items-center">
                  <h5 class="text-center text-green-300 font-bold">
                    จำนวน <span class="text-gray-500">{{ orderDetail.order_item.length }} </span> ใบ
                  </h5>
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">ผลเงินรางวัลสูงสุดที่เป็นไปได้</button>
                </div>
              </div>
            </div>
          </div>
          <!-- end detail order part 2 -->
        </aside>
        <main class="mr-10 p-2 w-2/3">
          <p class="text-start text-secondary fs-3 text-lg font-bold">ล็อตเตอรี่ที่สั่งซื้อ จำนวน {{ orderDetail.order_item.length }} ใบ</p>
          <div class="flex-wrap">
            <div v-for="item of orderDetail.order_item" :key="item">
              <img :src="apiStorage + 'upload/stock_lotto/' + item.stock_lotto.img_path" class="float-start shadow-md" width="250" height="150" />
            </div>
          </div>
        </main>
      </div>
      <Modelfade />
    </div>
  </div>
</template>

<script>
import Modelfade from '@/components/historypage/Modelfade'
import UserService from '../services/user.service'

export default {
  name: 'History',
  components: { Modelfade },
  data() {
    return {
      orderDetail: {},
      hasOrderDetail: false,
      id: this.$route.params.id,
      apiStorage: process.env.VUE_APP_UPLOAD_API
    }
  },
  async created() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
    try {
      const resOrderDetail = await UserService.getOrderById(this.id)
      if (resOrderDetail.data != null) {
        this.orderDetail = resOrderDetail.data
        this.hasOrderDetail = true
      }
    } catch (error) {
      console.log(error)
    }
  }
}
</script>
